<template lang="pug">
.reg-privacy
  .are-privacy.p-5
    img(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg" class="img-logo" alt="Wuwow Jr")
    h1.h1-privacy-title 六書堂數位學習所營相關網站及相關APP（以下稱本平台）為六書堂數位學習股份有限公司（以下稱「本公司」）所經營。本公司十分重視您的隱私權保護，將依個人資料保護法及本隱私權政策蒐集、處理及利用您的個人資料，並提供您對個人資料權利之行使與保護。若您不同意本隱私權政策之全部或部分者，請您停止使用本網站服務。
    hr
    h2.h2-privacy-title 1.本隱私權政策適用之範圍
    span 本隱私權政策適用於所有本公司透過本平台（包括：宣告使用本隱私權政策之網站或應用服務）向您蒐集之個人資料及其後續處理、利用行為。若您點選第三人網站之連結或廣告，拜訪各該網站或網頁時，有關您的隱私權保護，應依各該網站或網頁之經營者所定之隱私權政策規定處理，與本公司無涉。請您在於使用本平台前，確認您已審閱並同意本隱私權政策所列全部條款，若您不同意全部或部分者，則請勿使用本平台相關服務。您得隨時撤回您的同意，並停止使用相關服務。
    h2.mt-4.h2-privacy-title 2.應告知事項
    span (1)蒐集機關名稱: 六書堂數位學習股份有限公司。<br>
      | (2)蒐集目的：提供本平台相關服務、廣告、行銷、客戶管理、會員管理、學術研究及其他與第三人合作之行銷推廣活動 <br>
      | (3)收集資料類型：姓名、職稱、地址、聯絡電話、電子郵件信箱、年齡、性別、出生年月日等、興趣、休閒、消費模式、教育、技術或其他學歷、任職公司、薪資、健康與往來電子郵件、網站留言、系統自動紀錄之軌跡資訊等。 <br>
      | (4)個人資料利用期間：會員相關資料為本平台會員有效期間及終止後六個月；因網站或應用服務使用所蒐集之資料（不含身分識別內容的資訊），不受前開期間之限制。 <br>
      | (5)資料利用地區：本公司執行業務及伺服器主機所在地。 <br>
      | (6)資料分享：<br>
      p.txt-privacy-phase 除非符合下列其中一種情況，否則不會與六書堂數位學習股份有限公司以外的公司、機構或個人分享您的個人資訊：<br><br>
        | 一、事先徵得您的同意<br><br>
        | 二、本公司在事先徵得您同意的情況下，可與本公司以外的對象分享您的個人資訊。<br><br>
        | 三、供外部處理<br><br>
        | 本公司會將個人資訊提供給本公司關聯企業和信任的其他企業或人員進行處理，處理過程必須遵從我們的指示，也必須遵守本《隱私權政策》和任何其他適當的保密和安全措施。基於法律原因<br>
        | <br>
        | 此外，您同意本公司得分享您的個人資訊予本公司海內外關係企業及本公司委外之協力廠商（例如：提供物流、金流或活動贈品之廠商）處理或利用，如不含身分識別內容之資訊，亦得分享予廣告機制合作廠商及特定廣告客戶；如為本公司與其他廠商共同蒐集者，將於該共同蒐集之活動中載明；如本公司有需要將可識別會員資料與他人合作者，將會另行取得會員同意。 <br>
      | (7)個人資料利用方式：依蒐集目的範圍及本隱私權政策進行利用。針對受 GDPR 規定的個人資料會根據數個法律依據來處理資料。這些依據包含您提供的授權（您可以隨時撤銷授權）；為了履行服務條款等合約所必須執行的處理流程；以及合法企業權益，例如改善、個人化與開發服務、推廣顧客可能會喜歡的新功能或產品。<br>
      | (8)行使個人資料權利方式：，您就您的個人資料享有存取與匯出資料、查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除之權利。您可以透過寄送電子郵件至【
      a( :href="'mailto:'+companyEmail") {{companyEmail}}
      | 】（為避免電子郵件系統漏信或其他原因無法收悉，以本公司回覆收悉為準）或以書面郵寄至【
      a( :href="'mailto:'+companyEmail") {{companyEmail}}
      | 】方式行使上開權利，本公司將於收悉您的請求後，將會儘速處理。<br>
      | (9)個人資料選填說明：若本公司於蒐集個人資料時，相關網頁或文件載明為選填者，僅為提供您使用本平台相關服務更佳體驗，不影響您使用本平台相關服務之權益。<br>
      | <br>
      | 如果我們需要將您的資訊用於本《隱私權政策》未載明的其他目標，將事先徵求您的同意。<br>
    h2.mt-4.h2-privacy-title 3.個人資料蒐集、處理及利用說明
    span 若您有使用Facebook或其他社群服務，本公司及海內外關係企業可能透過相關社群服務系統設計，於取得您的同意後，將部分本平台的資訊發布於您的社群活動資訊頁面，若您不同意該等訊息之發布，請您勿點選同意鍵，或於事後透過各該社群服務之會員機制移除該等資訊或拒絕本平台相關服務繼續發布相關訊息。若有任何問題，仍可與本公司聯絡，本公司將協助您確認、處理相關問題。除依法應提供予司法、檢調機關、相關主管機關，或與本公司協力廠商為執行相關活動必要範圍之利用外，本公司將不會任意將您的個人資料提供予第三人。當本公司或本平台被其他第三者購併或收購資產，導致經營權轉換，且本公司所擁有之全部或部分使用者資訊亦可能在經營權轉換的狀況下移轉給第三人時，本公司會繼續維護個人資訊的機密性，並於個人資訊移轉或成為其他隱私權政策的涵蓋目標前，本公司會將相關細節公告於平台網站。在某些情況下，本公司會基於正當的商業或法律目的，將資料保留有限的一段時間。<br>
    h2.mt-4.h2-privacy-title 4.Cookie技術
    span 為便於日後的辨識，當您使用本平台相關服務時，本公司可能會在您的電腦或其他連網設備上設定與存取Cookie或其他類似技術。您可以透過設定您的個人電腦或上網設備，決定是否予許Cookie或其他類似技術的使用，若您關閉Cookie時，可能會造成您使用本平台相關服務時之不便利或部分功能限制。<br>
    h2.mt-4.h2-privacy-title 5.保密與安全性
    span 對於所有在工作中有必要接觸使用者資料之員工，本公司對每位員工僅提供有限度之使用權限，以確保使用者的個人資料避免不當的取用或外洩。為了保護您的帳戶及個人資料的安全，請您不要任意將個人帳號、密碼提供予第三人或允許第三人以您的個人資料申請帳號、密碼，否則，相關責任由您自行負擔。若您的帳號、密碼有外洩之虞，請您立即至本平台更改密碼，或通知本公司暫停該帳號（本公司可能會要求核對您的個人資料）。網際網路並不是一個安全的資訊傳輸環境，亦可能存在有心人士刻意透過社交或其他網站公開資訊蒐集之方式探知或蒐集您的個人資料，請您在使用本平台相關服務時，避免將敏感的個人資料提供予他人或在網站上公開揭露。<br>
    h2.mt-4.h2-privacy-title 6.未成年人保護
    span 未成年人使用本平台相關服務時，若同意本網站蒐集、利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。<br>
    h2.mt-4.h2-privacy-title 7.隱私權政策之修改
    span 當本隱私權政策有任何對您個人資料權益之變更或修改時，本公司將會在本平台相關服務公告，並以電子郵件通知您留存於本平台相關服務之電子郵件信箱。若您不同意該等變更或修改，請停止繼續使用本平台相關服務，並依本隱私權政策規定通知本公司停止蒐集、處理及利用您的個人資料。<br>
    h2.mt-4.h2-privacy-title 8.聯絡方式
    span 如果您對於自己的權利需要進一步的協助，請寄送電子郵件至【
      a( :href="'mailto:'+companyEmail") {{companyEmail}}
      | 】（為避免電子郵件系統漏信或其他原因無法收悉，以本公司回覆收悉為準）或以書面郵寄至【
      a( :href="'mailto:'+companyEmail") {{companyEmail}}
      | 】方式聯絡本公司，本公司將依據適用法律來回應您的要求。您也有權向適當的主管機關 (包括當地的資料保護主管機關)提出投訴。<br>
</template>
<script>
import companyConstant from '@/constants/company';
export default {
  name: 'Privacy',

  data() {
    return {
      companyEmail: companyConstant.INFO.CONTACT_EMAIL,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.reg-privacy { padding: 15px;}
.are-privacy { max-width: 1110px; margin: 0 auto; padding: 15px; background-color: #fff; font-size: 15px; letter-spacing: 2px;}
.are-privacy > .img-logo { display: block; max-width: 50%; margin: 0 auto;}
.are-privacy > .h1-privacy-title,
.are-privacy > .h2-privacy-title { font-size: 20px; font-weight: bolder;}
.are-privacy .txt-privacy-phase { padding-left: 20px;}
</style>
